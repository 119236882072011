
.background {
  position: relative;
  height: 100vh;
  overflow: auto;
  background-image: url(../../../assets/img/supermarket.jpg);
  background-size: cover;
  background-position: center;
}

.wrapper {
  height: 100%;
  background-color: rgb(255 255 255 / 44%);
}

.centerBox {
  width: 400px;
  max-width: 90%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loginBox {
  background-color: #bf1e2e;
  padding: 25px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.logo {
  max-height: 35px;
  max-width: 95%;
  margin: 60px 0;
}

.inputGroup {
  position: relative;
  margin-bottom: 10px;
}

.input {
  width: 100%;
  background-color: transparent;
  border: solid 1px white;
  padding: 10px;
  font-size: 18px;
  color: white;
  box-sizing: border-box;
  outline: none;
}

.input::placeholder {
  color: white;
}

.validation {
  text-align: right;
  color: white;
  font-size: .7em;
  font-weight: bolder;
  margin-top: .3em;
  height: 1em;
}

.button {
  border: none;
  background-color: white;
  color: #bf1e2e;
  font-size: 18px;
  padding: 8px 23px;
  margin-top: 30px;
  margin-bottom: 60px;
  outline: none;
  cursor: pointer;
}

.button:hover {
  background-color: lightgray;
}

.button[disabled] {
  opacity: .5;
  cursor: default;
  background-color: white !important;
}

.signUpText {
  color: white;
  margin-bottom: 20px;
  font-size: 14px;
}

.signUpLink {
  color: white;
  text-decoration: none;
  font-weight: bolder;
}

.trademark {
  color: #bf1e2e;
  padding: 10px;
  display: block;
  font-size: .9em;
  font-weight: bolder;
}

.loading {
  font-size: 40px;
  margin: 50px 0 50px 0;
}

.info {
  text-align: center;
}