/*
 | Style for SignUpMetricsWeb
 | Css class name convention:
 | h-sumw__[element][__sub-element]*[--modifier]
 */

.h-sumw__wrapper {
  background-color: #f3f3f3;
  font-size: 14px;
  min-height: 100vh;
  padding-bottom: 10em;
  box-sizing: border-box;
  position: relative;
}

.h-sumw__spacing-before {
  margin-top: 1em !important;
}

.h-sumw__spacing-after {
  margin-bottom: 1em !important;
}

.h-sumw__margin {
  padding: 0 1em;
}

.h-sumw__header {
  background-color: #be1e2d;
  height: 67px;
  padding: 0.75em 1em;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-sumw__header__logo {
  height: 100%;
  cursor: pointer;
}

.h-sumw__title {
  font-size: 2em;
  padding: 0;
  margin: 0;
}

.h-sumw__sub-title {
  font-size: 1.5em;
  padding: 0;
  margin: 0;
  font-weight: normal;
  color: #414042;
}

.h-sumw__sub-title-2 {
  font-size: 1.2em;
  padding: 0;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid black;
}

.h-sumw__info-text {
  margin: 0;
  padding: 0;
}

.h-sumw__label {
  display: block;
  font-size: 16px;
  padding: 8px 0;
  color: #414042;
  text-transform: inherit;
}

.h-sumw__input {
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #e5e5e5;
  color: #414042;
  outline: none;
}

.h-sumw__input-passwd {
  padding-right: 2.7em;
}

.h-sumw__input-wrapper {
  position: relative;
}

.h-sumw__input__toggle-visibility {
  position: absolute;
  right: 0;
  top: 1.15em;
  padding: 0.5em 0.8em;
}

.h-sumw__input-wrapper--error .h-sumw__label {
  color: red;
}

.h-sumw__input-wrapper--error .h-sumw__input {
  border-color: red;
}

.h-sumw__input__message {
  font-size: 0.8em;
  margin-top: 0.2em;
}

.h-sumw__grid {
  display: flex;
  margin: 0 -0.5em;
}

.h-sumw__col-10 {
  width: 100%;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-8 {
  width: 80%;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-7 {
  width: 70%;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-6 {
  width: 60%;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-5 {
  width: 50%;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-4 {
  width: 40%;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-3 {
  width: 30%;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-2 {
  width: 20%;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-steps {
  width: 20em;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.h-sumw__col-content {
  width: calc(100% - 20em);
  padding: 0 0.5em;
  box-sizing: border-box;
  background: #f4f4f4;
  padding: 32px;
  border-radius: 8px;
  height: fit-content;
}

.h-sumw__button {
  border: 0;
  font-weight: bolder;
  color: white;
  padding: 0.65em 4em;
  background-color: #be1e2d;
  white-space: nowrap;
  margin-right: 0.5em;
  border-radius: 8px;
}

.h-sumw__button--secundary {
  background: #3b3b3b;
}

.h-sumw__button-side {
  display: inline-block;
  padding: 0.65em 1em;
  font-weight: bolder;
  color: red;
}

.h-sumw__button:disabled {
  background-color: rgb(185, 185, 185) !important;
  cursor: auto !important;
}

.h-sumw__button:hover {
  background-color: #d4596d;
  cursor: pointer;
}

.h-sumw__button--secundary:hover {
  background-color: gray;
}

.h-sumw__table {
  width: 100%;
  border-collapse: collapse;
}

.h-sumw__table td {
  padding: 0.5em;
  font-weight: 400;
  color: #414042;
  border: 0;
}

.h-sumw__table td:first-child {
  padding-left: 0;
}

.h-sumw__table td:last-child {
  padding-right: 0;
}

.h-sumw__table tr:last-child td {
  padding-bottom: 0;
}

.h-sumw__table td:nth-child(2) {
  text-align: right;
}

.h-sumw__table td:nth-child(3) {
  text-align: right;
}

.h-sumw__table tr:last-child td {
  border: 0;
}

.h-sumw__uf-flag {
  height: 30px;
  border: unset;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 30px;
  object-fit: cover;
  border-radius: 30px;
}

.h-sumw__uf-n.ame {
  vertical-align: middle;
  font-weight: bolder;
}

.h-sumw__section__header {
  background-image: url("../../assets/img/market-banner.jpg");
  background-size: cover;
  background-position: bottom center;
  color: white;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 75%);
}

.h-sumw__section__header__content {
  padding: 8em 1em 1em 1em;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 6px 5px 0 rgb(0 0 0 / 75%) inset;
}

.h-sumw__step__label {
  background-color: #be1e2d;
  color: white;
  font-size: 1.5em;
  padding: 0.1em;
  width: 1.2em;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.h-sumw__step--disabled .h-sumw__step__label {
  background-color: #cccccc;
}

.h-sumw__step--active .h-sumw__step__label {
  background-color: #be1e2d;
}

.h-sumw__step__description {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: middle;
}

.h-sumw__step--disabled .h-sumw__step__description {
  font-weight: normal;
  color: #bbbbbb;
}

.h-sumw__step {
  position: relative;
  z-index: 100;
  padding: 12px 0;
}

.h-sumw__steps {
  position: relative;
}

.h-sumw__steps:before {
  content: " ";
  height: 16em;
  width: 0;
  border: dashed 1px #cccccc;
  display: block;
  position: absolute;
  top: 12px;
  left: 0.95em;
}

.h-sumw__footer {
  color: white;
  background-color: rgb(190 30 45);
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  font-weight: bolder;
  border: 0;
  padding: 60px 32px;
  box-shadow: unset;
  display: flex;
  flex-direction: column;
}

.h-sumw__footer a {
  color: white !important;
}

.h-sumw__footer__contact {
  list-style: none;
  margin: 0;
  margin-top: 1em;
  padding: 0;
  font-weight: normal;
}

.h-sumw__footer__shadow {
  height: 0.5em;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.h-sumw__warn-box {
  border: 1px solid rgb(181, 147, 12);
  border-radius: 5px;
  padding: 10px;
  background-color: rgb(254, 250, 212);
  margin: 10px 0px;
  color: rgb(181, 147, 12);
  font-size: 12px;
  margin-bottom: 20px;
}

.h-sumw__currency {
  margin: 0.5em 0;
  font-size: 2em;
  font-weight: bolder;
}

.h-sumw__currency__info {
  font-size: 0.6em;
  margin: 0 0.2em;
}

.h-sumw__price-table {
  border-collapse: collapse;
}

.h-sumw__price-table td {
  border: solid 1px black;
  padding: 0.5em;
}

.h-sumw__price-table td:last-child {
  border-right: 0;
}

.h-sumw__price-table td:first-child {
  border-left: 0;
}

.h-sumw__price-table tr:first-child td {
  border-top: 0;
}

.h-sumw__price-table tr:last-child td {
  border-bottom: 0;
}

.h-sumw__password__indicator {
  height: 0.8em;
  border: solid 1px black;
  margin-top: 0.5em;
  margin-bottom: 0.1em;
  width: 20em;
  max-width: 100%;
}

.h-sumw__password__message {
  font-size: 0.8em;
  color: green;
}

.h-sumw__password__message--fail {
  color: red;
}

.h-sumw__password__indicator__bar {
  height: 100%;
}

.h-sumw__password__indicator__bar--low {
  background-color: yellow;
}

.h-sumw__password__indicator__bar--medium {
  background-color: orange;
}

.h-sumw__password__indicator__bar--high {
  background-color: green;
}

.h-sumw__credit-card {
  margin-top: 1em;
  height: 10em;
  width: 20em;
  border-radius: 0.5em;
  background-color: #99152b;
  background-image: linear-gradient(51.24deg, #99152b 41.56%, #9e2f41 72.05%);
  color: white;
  font-weight: bolder;
  position: relative;
  overflow: hidden;
}

.h-sumw__payment-content {
  width: 25em;
}

.h-sumw__credit-card__number {
  position: absolute;
  bottom: 32%;
  left: 5%;
  font-size: 1.3em;
}

.h-sumw__credit-card__name {
  position: absolute;
  bottom: 20%;
  left: 5%;
  font-size: 0.8em;
  white-space: nowrap;
}

.h-sumw__credit-card__validate {
  position: absolute;
  bottom: 8%;
  left: 5%;
  font-size: 0.8em;
  white-space: nowrap;
}

.h-sumw__credit-card__chip {
  height: 1.8em;
  width: 2.5em;
  border-radius: 0.4em;
  background-color: lightgray;
  position: absolute;
  top: 30%;
  left: 5%;
}

.h-sumw__table--credit-cards {
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
}

.h-sumw__table--credit-cards tr:nth-child(2n + 1) {
  background-color: lightgray;
}

.h-sumw__table--credit-cards thead tr {
  background-color: #99152b !important;
  color: white;
}

.h-sumw__table--credit-cards td,
.h-sumw__table--credit-cards th {
  white-space: nowrap;
  padding: 0.5em !important;
  text-align: right;
}

.h-sumw__table--credit-cards td:nth-child(1) {
  text-align: center;
}

.h-sumw__table--credit-cards th {
  font-size: 0.9em;
  text-align: center;
}

.h-sumw__step.h-sumw__step--active .h-sumw__step__description {
  color: #99152b;
}

.h-sumw__wrapper {
  background-color: #ffffff;
}

.h-sumw__col-content p.h-sumw__info-text {
  padding: 12px 0;
  color: #414042;
  font-weight: bolder;
}

span.hd-featured {
  color: #be1e2d;
  font-size: 28px;
  padding: 0 8px;
  font-weight: 700;
}

.h-sumw_metricsweb .h-sumw__currency__info {
  font-size: 14px;
  width: 100%;
  align-items: center;
  font-weight: 400;
}

.h-sumw_metricsweb .h-sumw__sub-title {
  font-size: 16px;
  padding: 8px 0;
}

.h-sumw_metricsweb .h-sumw_submit {
  width: 100%;
  display: flex;
  justify-content: center;
}

.h-sumw_metricsweb .h-sumw__currency,
.h-sumw_metricsweb .h-sumw_buttonwrap {
  text-align: center;
}

.h-sumw_metricsweb > button.h-sumw__button:nth-last-child(1) {
  margin-left: calc(50% - 80px);
}

.h-sumw__grid.h-sumw__spacing-before .h-sumw__col-3 {
  width: 100%;
  margin: 0 0px;
}

.h-sumw__grid.h-sumw__spacing-before .h-sumw__col-3:nth-child(2) {
  display: none;
}

.h-sumw__grid.h-sumw__spacing-before.fulled {
  justify-content: space-between;
}

.h-sumw__grid.h-sumw__spacing-before.fulled .h-sumw__col-3:nth-child(2) {
  display: block;
}

.h-sumw__grid.h-sumw__spacing-before.fulled .h-sumw__col-3 {
  width: 50%;
  margin: 0;
}

.h-sumw__margin {
  padding: 32px;
  margin-top: 0 !important;
}

tr td span {
  padding-left: 12px;
}

.umw_next {
  margin: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: 250px;
}

.terms-accept {
  font-size: 13px;
  color: #636264;
  padding: 8px 0;
  display: block;
  margin: auto;
  max-width: 250px;
}

.umw_next .terms-accept a {
  color: #3f51b5;
  justify-content: center;
  text-decoration: none;
}

.sumw_next .h-sumw__button {
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  background: #be1e2d;
  padding: 12px 4em;
}

.h-sumw__col-content .h-sumw__grid .h-sumw__col-5 {
  width: 100%;
}

.h-sumw__input__toggle-visibility {
  margin-top: 18px;
}

.h-sumw__payment-content {
  width: 100%;
}

.h-sumw__grid.h-sumw__spacing-after {
  max-width: 350px;
}

div.h-sumw__grid > div.h-sumw__col-content > div.h-sumw__spacing-before {
  margin-top: 1em !important;
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
}

.link-acccess-plataform {
  font-weight: 700;
  color: #be1e2d;
}

p.h-sumw__spacing-before.h-sumw__spacing-after.thankyou {
  margin: 0 !important;
  padding-bottom: 32px;
  color: #414042;
}

.h-sumw__header__logo {
  height: 23px;
  margin: 32px 0;
}

.h-sumw__body.h-sumw__margin.h-sumw__spacing-before {
  margin-bottom: 64px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.hn-container {
  font-weight: 400;
  display: inline-flex;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  justify-content: space-between;
}

.hn-box {
  max-width: 270px;
}

.hn-box li {
  list-style: none;
  display: flex;
}

.hn-box ul#menu-rodape {
  padding: 0;
}

.h-sumw__footer a {
  text-decoration: none;
  padding: 4px 0;
  color: #9b9aac !important;
  font-weight: 400;
  font-size: 17px;
}

.hn-text {
  display: flex;
  flex-direction: column;
}

.hn-text {
  padding: 8px 0;
}

.hn-text span {
  padding: 8px 0;
}

.hn-container .hn-box:nth-child(3) a {
  color: #ffffff !important;
  font-weight: 500;
}

.hn-text-phone {
  font-size: 13px;
}

.hn-text-phone a {
  font-size: 13px !important;
}

.h-sumw__subheader {
  text-align: center;
}

.h-sumw__subheader h3 {
  font-size: 32px;
  color: #be1e2d;
  margin-bottom: 0;
}

.network-social-icon {
  width: 21px;
}

.network-wrap {
  display: flex;
  padding: 0;
}

.network-wrap li {
  padding: 0 12px;
}

.network-wrap li {
  display: flex;
  padding: 0 8px;
  padding-top: 64px;
  justify-content: flex-start;
}

.h-sumw__wrapper {
  padding-bottom: 0;
}

.h-sumw__wrapper .infoIcon {
  width: 13px;
  background: #c7c0c0;
  border-radius: 5px;
  margin: 4px;
  color: #fff;
}

.h-sumw_renewal {
  padding-left: 5px;
  margin: 4px;
}

.h-sumw_renewal {
  margin-top: 0 !important;
  font-size: 12px;
  color: #636264;
  top: -1em;
  position: relative;
}

.h-sumw__history-information {
  font-size: 12px;
  color: #414042;
}

.h-sumw__forgot-password a,
.h-sumw__forgot-password p {
  padding: 0.5em 0.1em;
  color: #be1e2d;
  font-weight: bolder;
  text-decoration: none;
  font-size: 0.8em;
  cursor: pointer;
}

.h-sumw__wrapper {
  min-height: calc(100vh - 293px);
}

.h-sumw__wrapper_cumpom .h-sumw__input-wrapper {
  width: calc(100% - 220px);
}

.h-sumw__spacing-before.h-sumw__spacing-after.h-sumw__wrapper_cumpom {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.h-sumw__spacing-before.h-sumw__spacing-after.h-sumw__wrapper_cumpom
  button.h-sumw__button {
  max-height: 30.31px;
}

.h-sumw__revision-step .h-sumw__checkout_wrap {
  align-items: center;
  justify-content: center;
  display: flex;
}

.h-sumw__revision-step span.hd-featured {
  padding: 0 8px 0 0;
}

.h-sumw__revision-step h3.h-sumw__sub-title-2 {
  margin: 0;
  font-weight: normal;
  color: #414042;
  font-size: 16px;
  padding: 8px 0;
  border-bottom: 0px solid #cccccc;
}

.hd-fgv__footer nav button {
  color: white;
  text-decoration: none;
  padding: 4px 0;
  background: unset;
  border: unset;
  font-size: 14px;
  cursor: pointer;
}

.hd-fgv__footer nav button:hover {
  text-decoration: underline;
}
.h-sumw__revision-step span.hd-featured {
  padding: 0 8px 0 0;
}

.hdmsps-filter-label {
  display: block;
  font-size: 16px;
  padding: 8 px 0;
  color: #414042;
  text-transform: inherit;
  font-weight: 400;
}

.h-sumw__input-wrapper .hdmsps-filter {
  padding: 0;
}

.h-sumw__input-wrapper .hdmsps-options {
  border: solid 1px #e5e5e5;
}

.session-info {
  padding: 0px;
  border-radius: 5px;
  font-size: 12px;
  color: #414042;
}

#session-validator {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  backdrop-filter: blur(2px);
  background-color: #0000004f;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.session-validator-wrapper {
  width: 550px;
  background: #fff;
  border-radius: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 44px 32px;
}

.session-validator-wrapper h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.session-validator-wrapper p {
  width: 80%;
  padding: 2px 6px;
}

.session-validator-wrapper button {
  width: 80%;
  padding: 12px;
  border: 0;
  background: var(--main-color);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
