.boxes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
    justify-content: left;
    flex-direction: row;
    align-items: center;
    max-width: 800px;
}

.box button {
    min-height: 183px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    margin: 16px 8px;
    border: 0;
    min-width: 200px;
    flex-wrap: wrap;
    cursor: pointer;
}

.box button:hover {
    background-color: lightgray;
}

.box button span {
    padding: 12px;
    font-size: 16px;
}

@media (max-width: 800px) {
    .boxes {
        justify-content: center;
    }
    
 }

 @media (max-width: 490px) {
    .box button {
        width: 200px;
        min-width: 170px;
    }
 }

 .noTextDecoration {
    text-decoration: none;
 }