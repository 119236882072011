.clearfix::after {
  clear: both;
  display: block;
  content: " ";
}

.h-development__wrapper {
  background-color: #99152b;
  background-image: linear-gradient(51.24deg, #99152b 41.56%, #9e2f41 72.05%);
  height: 100vh;
  position: relative;
}

.h-development__box {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.h-development__description,
.h-development__description-2 {
  font-weight: bolder;
  color: white;
  margin: 0;
  padding: 0;
}

.h-development__description {
  font-size: 1.5em;
  margin-top: 0.8em;
}

.h-development__description-2 {
  font-size: 0.8em;
}

.h-embed-pdf-cronograma-dashboard {
  width: 100%;
  min-height: 540px;
  margin-top: 16px;
}

@media (max-width: 490px) {
  .h-embed-pdf-cronograma-dashboard {
    min-height: unset;
    height: auto;
  }

}

/* FGV - Hero Component */

.hd-fgv__hero {
  min-height: 600px;
  background: #fde9ee;
  display: flex;
}

.hd-fgv__hero  .content div:nth-child(1) {
  padding-right: 32px;
}


.hd-fgv__hero .content h2 {
  color: #b92028;
  font-size: 2.36em;
  max-width: 535px;
  margin: 0;
}

.hd-fgv__hero .content p {
  max-width: 535px;
  line-height: 2;
  font-size: 16px;
}

.hd-fgv__hero .content ul {
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
}

.hd-fgv__hero .content ul button {
  display: flex;
  list-style: none;
  background: unset;
  padding: 8px 12px;
  margin: 4px;
  border: 2px solid;
  color: #b92028;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
}

.btn-extra-section {
  text-decoration: none;
  background-color: #b92028;
  color: white;
  padding: 0.5em 1em;
  border-radius: 2em;
  font-weight: bolder;
  border: 0;
  display: inline-block;
  font-size: 13px;
}
