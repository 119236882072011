@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body > iframe {
  pointer-events: none;
}

.layout {
  position: absolute;
  top: 0;
  max-width: 100%;
  opacity: 1;
  pointer-events: none;
  z-index: 1000;
}

.toggle-layout {
  z-index: 1001;
  position: fixed;
  bottom: 10px;
  left: 10px;
  padding: 5px;
  background-color: black;
  color: white;
  cursor: pointer;
  user-select: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.modal-msg {
  text-align: center;
  margin-top: 20px;
}

.hd-featured {
  font-weight: bolder;
  font-size: 1.2em;
}

.hd-bold {
  font-weight: bolder;
}

.hd-pbi-btn {
  position: fixed;
  top: 0;
  left: 0;
  border: solid 1px rgb(153, 21, 43);
  background-color: rgb(153, 21, 43);
  color: white;
  padding: 10px;
  font-size: 24px;
  z-index: 2500;
  cursor: pointer;
}

.hd-pbi-btn:hover {
  color: rgb(153, 21, 43);
  background-color: white;
}

.hd-dialogs {
  padding: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1500;
}

.hd-dialog-body {
  background-color: rgb(153, 21, 43);
  padding: 20px;
  color: white;
  width: 200px;
  margin-top: 20px;
  border: solid 2px white;
  position: relative;
}

.hd-dialog-title {
  margin-bottom: 10px;
  font-weight: bolder;
}

.hd-dialog-close {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
}

.hd-dialog-close:hover {
  color: rgb(153, 21, 43);
  background-color: white;
  cursor: pointer;
}

.hd-dialog-button {
  margin-top: 10px;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  cursor: pointer;
}

.hd-dialog-button:hover {
  background-color: white;
  color: rgb(153, 21, 43);
}

.hd-dash {
  overflow-x: hidden;
}

.hd-dash-menu {
  background-color: rgb(153, 21, 43);
  height: 100vh;
  z-index: 2000;
  width: 300px;
  position: fixed;
  top: 0;
  left: -300px;
  overflow-x: auto;
  padding-bottom: 50px;
  box-sizing: border-box;
}

.hd-dash-menu-filter-input-wrapper {
  margin: 14px 0 4px 0;
  padding: 0 8px;
}

.hd-dash-menu-filter-input {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border: solid 1px white;
  padding: 4px 8px;
  color: white;
  border-radius: 0;
  font-size: 14px;
}

.hd-dash-menu-filter-input::placeholder {
  color: white;
}

.hd-scroll::-webkit-scrollbar {
  width: 3px;
  border: 0;
}

.hd-scroll.hd-scroll-principal::-webkit-scrollbar {
  width: 5px;
}

.hd-scroll::-webkit-scrollbar-thumb {
  background: white;
  border: 0;
}

.hd-scroll.hd-scroll-principal::-webkit-scrollbar-thumb {
  background: rgb(158, 40, 60);
}

.hd-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5);
  border: 0;
}

.hd-scroll.hd-scroll-principal::-webkit-scrollbar-track {
  background: #d8d8d8;
}

.hd-scroll::-webkit-scrollbar-thumb:hover {
  background-color: lightgray;
}

.hd-scroll.hd-scroll-principal::-webkit-scrollbar-thumb:hover {
  background-color: red;
}

.hd-dash-menu-btn {
  color: white;
  float: left;
  padding: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.hd-dash-menu-btn:hover {
  background-color: white;
  color: rgb(153, 21, 43);
}

.hd-dash-menu-top {
  color: white;
  font-weight: bolder;
  padding: 8px;
}

.hd-dash-menu-top-title {
  font-size: 25px;
}

.hd-dash-menu-item {
  display: block;
  color: white;
  text-decoration: none;
  padding: 8px;
  font-size: 14px;
}

.hd-dash-menu-section-title {
  display: block;
  color: white;
  text-decoration: none;
  padding: 8px;
  font-size: 14px;
  font-weight: bolder;
  border-bottom: solid 1px white;
}

.hd-dash-menu-item:hover {
  background-color: black;
}

.hd-dash-content {
  min-height: 100vh;
}

.hd-dash-menu,
.hd-dash-content,
.hd-dash-backdrop,
.hd-pbi-btn {
  transition: transform 0.5s;
}

.hd-dash-open .hd-dash-menu {
  transform: translateX(300px);
}

.hd-dash-open .hd-dash-content {
  transform: translateX(300px);
}

.hd-dash-backdrop {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: -100vh;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.hd-dash-open .hd-dash-backdrop {
  top: 0;
  transform: translateX(300px);
}

.hd-dash-open .hd-pbi-btn {
  transform: translateX(300px);
}

.hd-dash-button {
  width: 10px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  cursor: pointer;
}

.hd-dash-open .hd-dash-button {
  display: none;
}

.hd-dash-button:hover {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.hdh-dashboard-link {
  color: black;
  display: block;
  font-size: x-large;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  list-style-type: none;
  position: relative;
  margin-bottom: 0.5em;
  text-decoration: none;
}

.hdh-dashboard-link:hover {
  color: #bf1e2e;
}

.hdmsps-body {
  background-color: rgb(243, 243, 243);
}

.hdmsps-header {
  background-color: rgb(153, 21, 43);
  padding: 10px;
  box-shadow: rgb(0 0 0 / 75%) 0px 1px 2px 0px;
}

.hdmsps-logo {
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.hdmsps-title-separator {
  display: inline-block;
  vertical-align: middle;
  width: 2px;
  height: 15px;
  background-color: white;
  margin: 0 10px 0 15px;
}

.hdmsps-title {
  display: inline-block;
  margin: 0;
  font-size: 20px;
  vertical-align: middle;
  color: white;
}

.hdmsps-filters {
  margin: 0 -5px;
  display: flex;
}

.hdmsps-filter-box:after {
  display: block;
  width: 0;
  height: 0;
  border-left: 0.25em solid transparent;
  border-right: 0.25em solid transparent;
  border-top: 0.45em solid black;
  content: "";
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.hdmsps-filter-box::before {
  display: block;
  width: 20px;
  height: 100%;
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
}

.hdmsps-filter-label {
  font-size: 15px;
  font-weight: bolder;
}

.hdmsps-filter-input,
.hdmsps-filter-box {
  background-color: white;
  border: solid 1px gray;
  padding: 3px;
  font-size: 14px;
  position: relative;
  width: 100%;
  display: block;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
}

.hdmsps-filter-box {
  cursor: default;
  user-select: none;
}

.hdmsps-filter-metric {
  display: flex;
}

.hdmsps-filter-metric-item:last-child {
  border-right: solid 1px gray;
}

.hdmsps-filter-metric-item {
  flex-grow: 1;
  text-align: center;
  height: 40px;
  line-height: 44px;
  font-weight: bolder;
  background-color: white;
  text-transform: uppercase;
  border: solid 1px gray;
  border-right: 0;
  cursor: pointer;
}

.hdmsps-filter-metric-item:hover {
  background-color: lightgray;
}

.hdmsps-filter-metric-item-selected {
  background-color: gray !important;
  color: white;
}

.hdmsps-filter {
  padding: 10px;
  flex-grow: 1;
  flex-basis: 0;
}

.hdmsps-options-wrapper {
  position: relative;
}

.hdmsps-options {
  background-color: white;
  border: solid 1px gray;
  border-top: 0;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: 400px;
  overflow: auto;
  z-index: 100;
}

.hdmsps-option {
  padding: 3px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.hdmsps-filter--disabled .hdmsps-option {
  cursor: default;
  background-color: white !important;
}

.hdmsps-option-disabled {
  cursor: default;
  background-color: white !important;
  color: gray;
}

.hdmsps-option:hover {
  background-color: #f490a0;
}

.hdmsps-option-select {
  display: block;
  padding: 1px;
  border: solid 1px gray;
  height: 8px;
  width: 8px;
  flex-basis: 8px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 4px;
}

.hdmsps-option span {
  display: block;
}

.hdmsps-option-select-active:after {
  content: " ";
  display: block;
  background-color: gray;
  width: 100%;
  height: 100%;
}

.hdmsps-options-separator {
  height: 1px;
  background-color: gray;
}

.hdmsps-table {
  width: 100%;
  border-spacing: 0;
}

.hdmsps-table td {
  padding: 5px;
  text-align: center;
}

.hdmsps-table td:nth-child(1) {
  text-align: left;
}

.hdmsps-table thead td {
  text-align: center;
  background-color: rgb(47, 54, 64);
  color: white;
  font-weight: bolder;
}

.hdmsps-table tbody tr:hover td {
  background-color: #99152b;
  color: white;
}

.hdmsps-table-manufacturer {
  font-weight: bolder;
  background-color: #b1aeae !important;
}

.hdmsps-table-brand td:nth-child(1) {
  padding-left: 20px;
}

.hdmsps-table-color {
  background-color: #d8d8d8;
}

.hdmsps-others-td {
  position: relative;
}

.hdmsps-others-btn {
  padding: 1px 8px;
  background-color: rgb(47, 54, 64);
  color: white;
  display: inline;
  user-select: none;
  font-size: 13px;
}

.hdmsps-others-btn:hover {
  background-color: black;
  cursor: pointer;
}

.hdmsps-others-btn:hover + .hdmsps-others-list {
  display: block;
}

.hdmsps-others-list {
  height: 300px;
  overflow-y: auto;
}

.hdmsps-others-list div {
  padding: 10px 4px;
  text-align: center;
  border-bottom: solid 1px black;
  box-sizing: border-box;
}

.hdmsps-others-filter {
  width: 100%;
  margin: 10px 0 0 0;
  padding: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.hdmspf-body {
  padding: 5px;
  font-size: 1vw;
  background-color: rgb(243, 243, 243);
}

@media (min-width: 1400px) {
  .hdmspf-body {
    font-size: 15px;
  }
}

/* hdpd stands for Horus Dashboards Professional Dashboard */

.hdpd-right-part {
  height: calc(100vh - 120px);
  width: 50%;
  float: left;
  padding: 5px;
  box-sizing: border-box;
}

.hdpd-box {
  background-color: white;
  border: solid 1px black;
  box-sizing: border-box;
}

.hdpd-metric-image {
  width: 100%;
  height: 100%;
}

.hdpd-tbc-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.hdpd-tbc-header {
  text-align: center;
  font-size: 1.1vw;
  position: absolute;
  top: 10px;
  width: 100%;
  text-transform: uppercase;
  color: black;
  font-weight: bolder;
}

.hdpd-tdc-content {
  margin-top: 40px;
}

.hdpd-tbc-percent-wrapper {
  font-size: 40px;
  font-size: 3.1vw;
  padding: 10px 20px 0 20px;
}

.hdpd-tbc-percent-icon,
.hdpd-tbc-percent {
  float: left;
}

.hdpd-tbc-percent-icon {
  width: 30%;
}

.hdpd-tbc-percent {
  width: 70%;
  color: #616161;
  text-align: right;
}

.hdpd-tbc-pp-py-tables {
  padding: 0 20px;
  margin: 5px -30px 0 -30px;
}

.hdpd-tbc-pp-py-table {
  width: 50%;
  float: left;
  padding: 0 30px;
  box-sizing: border-box;
}

.hdpd-tbc-pp-py-table-body {
  font-size: 20px;
  padding: 5px 0;
}

.hdpd-tbc-pp-py-table-indicator {
  width: 0;
  height: 0;
  border-left: 0.7em solid transparent;
  border-right: 0.7em solid transparent;
  border-bottom: 1em solid green;
  display: inline;
  vertical-align: middle;
  position: relative;
  top: -1.2em;
}

.hdpd-tbc-pp-py-table-indicator.__down {
  border-bottom: 0;
  border-left: 0.7em solid transparent;
  border-right: 0.7em solid transparent;
  border-top: 1em solid red;
  top: unset;
  bottom: -1.2em;
}

.hdpd-tbc-pp-py-table-value {
  display: inline;
  vertical-align: middle;
  padding: 5px;
  font-size: 1vw;
}

.hdpd-tbc-pp-py-table-header {
  border: solid 1px gray;
  border-left: 0;
  border-right: 0;
  padding: 1vh 0;
}

.hdpd-tbc-bt {
  padding: 10px;
  width: 100%;
  border-spacing: 0;
  text-align: right;
}

.hdpd-tbc-bt th {
  text-align: right;
  border: solid 1px gray;
  border-left: 0;
  border-right: 0;
  padding: 12px 0;
}

.hdpd-tbc-bt th,
.hdpd-tbc-bt td {
  padding: 4px;
  font-size: 0.8vw;
}

.hdpd-tbc-bt th:nth-child(1),
.hdpd-tbc-bt td:nth-child(1) {
  border-right: solid 1px gray;
  text-align: left;
}

.hdpd-bbc-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.hdpd-bbc-left {
  height: 100%;
  width: 40%;
  box-sizing: border-box;
  float: left;
}

.hdpd-bbc-bars {
  overflow-y: auto;
  padding: 0 10px;
  height: calc(100% - 60px);
}

.hdpd-bbc-bars-label {
  width: 50%;
  float: left;
  text-align: right;
  box-sizing: border-box;
  font-size: 16px;
  position: relative;
  padding: 0 5px;
  top: 20px;
  transform: translateY(-50%);
}

.hdpd-bbc-bars-value {
  width: 50%;
  float: left;
}

.hdpd-bbc-bars-value-bar {
  background-color: #b30d27;
  height: 40px;
  position: relative;
  margin-bottom: 5px;
}

.hdpd-bbc-bars-value-bar-label {
  position: absolute;
  left: 100%;
  font-size: 18px;
  padding: 8px 5px;
}

.hdpd-bbc-bars-value-bar-label.__inset {
  left: unset;
  right: 0;
  color: white;
}

.hdpd-bbc-header {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 19px;
  padding: 15px 0;
  text-align: center;
}

.hdpd-bbc-header-small {
  font-size: 0.7em;
}

.hdpd-bbc-right {
  float: left;
  width: 60%;
}

.hdpd-bbc-tables {
  margin: 0 10px;
}

.hdpd-bbc-table-wrapper {
  width: 50%;
  height: 50%;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  float: left;
}

.hdpd-bbc-table {
  width: 100%;
}

.hdpd-bbc-table th {
  border-bottom: solid 1px gray;
  padding: 10px 0;
}

.hdpd-bbc-table th,
.hdpd-bbc-table td {
  text-align: center;
  width: 1px;
}

.hdpd-bbc-table tr *:nth-child(1) {
  text-align: left;
  width: 100%;
}

.hdpd-bbc-indicator {
  width: 0;
  height: 0;
  border-left: 0.4em solid transparent;
  border-right: 0.4em solid transparent;
  border-bottom: 0.8em solid green;
  display: inline;
  vertical-align: middle;
  position: relative;
  top: -1.1em;
}

.hdpd-bbc-indicator.__down {
  border-bottom: 0;
  border-top: 0.8em solid red;
  top: unset;
  bottom: -1.1em;
}

.hdpd-evol-wrapper {
  height: 100%;
  box-sizing: border-box;
}

.hdpd-evol-title {
  color: gray;
  text-transform: uppercase;
  font-weight: bolder;
  padding: 10px;
  font-size: 20px;
  text-align: center;
}

.hdpd-evol-content {
  height: calc(100% - 50px);
}

.hdpd-evol-x-axis {
  height: 100%;
  width: 60px;
  position: relative;
  display: inline-block;
}

.hdpd-evol-x-axis-indicator {
  position: absolute;
  right: 10px;
  transform: translateY(50%);
}

.hdpd-evol-bars {
  float: left;
  width: 75%;
  height: 100%;
  padding: 10px 5px 80px 5px;
  box-sizing: border-box;
  white-space: nowrap;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
}

.hdpd-evol-bar {
  width: 60px;
  margin: 0 5px;
  height: 100%;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: top;
}

.hdpd-evol-bar-label {
  position: absolute;
  bottom: -40px;
  transform: translateX(-20px) rotate(-25deg);
}

.hdpd-evol-bar-item {
  position: relative;
}

.hdpd-evol-bar-value {
  color: white;
  font-weight: bolder;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  font-size: 0.9vw;
}

.hdpd-evol-legend {
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 10px 0;
}

.hdpd-evol-legend-item {
  margin-bottom: 10px;
  font-size: 0.9vw;
}

.hdpd-evol-legend-item-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.hdpd-evol-legend-item-name {
  vertical-align: middle;
}

.hdpd-loading {
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  z-index: 1000;
}

.hdpd-loading--contain {
  position: absolute;
}

.hdpd-loading-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.hdpd-loading-label {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bolder;
}

.hdpd-loading .Loading-spinner {
  width: 100px;
  height: 100px;
}

.hdpd-loading .Loading-spinner > div {
  background-color: white;

  margin: 0 2px;
  width: 10px;
}

.hdpd-error {
  margin-top: 20px;
  text-align: center;
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
  width: 100%;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker--disabled {
  background-color: gray;
  color: #6d6d6d;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
  height: 24px;
  background-color: white;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}

.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}

.react-date-picker__button svg {
  display: inherit;
  width: 14px;
  height: 14px;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-date-picker__calendar--closed {
  display: none;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.hdppl-filters {
  display: flex;
  flex-wrap: wrap;
}

.hdppl-filters + .hdppl-filters {
  margin-top: -10px;
}

.hdppl-filters > div {
  width: 16.6%;
  box-sizing: border-box;
}

.hdppl-filters-buttons {
  display: flex;
  margin: 10px;
}

.hdppl-filters-button {
  text-align: center;
  height: 40px;
  line-height: 44px;
  font-weight: bolder;
  background-color: white;
  text-transform: uppercase;
  border: solid 1px gray;
  border-left: 0;
  cursor: pointer;
  padding: 0 10px;
  white-space: nowrap;
}

.hdppl-filters-button:hover {
  background-color: lightgray;
}

.hdppl-filters-button:first-child {
  border-left: solid 1px gray;
}

.hdppl-body {
  background-color: rgb(243, 243, 243);
  min-height: 100vh;
  min-height: calc(100vh - 44px);
}

.hdppl-content {
  display: flex;
}

.hdppl-content-active {
  padding: 0 5px;
}

.hdppl-content-active .hdppl-histogram-wrapper {
  display: block;
}

.hdppl-content-active > div {
  padding: 0 5px;
}

.hdppl-histogram-wrapper {
  display: none;
  flex-basis: 33%;
}

.hdppl-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px lightgray;
  background-color: white;
  padding: 15px;
}

.hdppl-info-boxes {
  display: flex;
  margin: 0 -5px 10px -5px;
}

.hdppl-info-boxes .__info-box-wrapper {
  flex-basis: 50%;
}

.hdppl-info-boxes .__info-box {
  text-align: center;
}

.hdppl-info-boxes .__info-box-title {
  font-weight: bolder;
  font-size: 16px;
  border-bottom: solid 1px black;
}

.hdppl-info-boxes .__info-box-description {
  font-size: 26px;
  font-weight: lighter;
}

.hdppl-histogram-title {
  font-size: 20px;
  text-align: center;
  font-weight: bolder;
  border-bottom: solid 1px black;
  margin-bottom: 10px;
}

.hdppl-table {
  width: 100%;
  border-spacing: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px lightgray;
}

.hdppl-content-active .hdppl-table-wrapper {
  flex-basis: 66%;
}

.hdppl-table-responsive {
  height: calc(100vh - 225px);
  border-bottom: solid 1px lightgray;
  overflow: auto;
}

.hdppl-table th,
.hdppl-table td {
  text-align: center;
  padding: 3px;
  box-sizing: border-box;
}

.hdppl-table th {
  background-color: rgb(47, 54, 64);
  color: white;
  font-weight: bolder;
}

.hdppl-table tr:nth-child(2n) td {
  background-color: #d8d8d8;
}

.hd-modal-open .hd-modal-back {
  display: block;
}

.hd-modal-back {
  display: none;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.hd-modal-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.5s;
}

.hd-modal-open .hd-modal-center {
  opacity: 1;
}

.hd-modal-body {
  padding: 10px;
  width: 500px;
  background-color: white;
}

.hd-modal-close {
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.hd-modal-close:hover {
  text-decoration: underline;
}

.hd-histogram {
  display: flex;
  height: 300px;
  height: calc(100vh - 350px);
  padding: 10px 0 35px 0;
  position: relative;
}

.hd-histogram .__y-axis {
  text-align: right;
  border-right: solid 1px black;
  position: relative;
  flex-basis: 50px;
}

.hd-histogram .__y-axis-indicator {
  right: 0;
  position: absolute;
  padding-right: 5px;
  font-size: 12px;
  transform: translateY(50%);
}

.hd-histogram .__y-axis-indicator:after {
  content: " ";
  height: 1px;
  width: 5px;
  background-color: black;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
}

.hd-histogram .__y-axis-indicator-line {
  border-bottom: dashed 1px rgb(219, 219, 219);
  width: 100%;
  background-color: black;
  display: block;
  position: absolute;
  right: 0;
  opacity: 0.2;
}

.hd-histogram .__x-axis {
  display: flex;
  position: relative;
  top: 1px;
  flex-basis: calc(100% - 50px);
}

.hd-histogram .__x-axis-bar-wrapper {
  position: relative;
  border-bottom: solid 1px black;
  flex: 1 1 0px;
}

.hd-histogram .__x-axis-bar {
  width: 85%;
  background-color: rgb(153, 21, 43);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.hd-histogram .__x-axis-bar-bound {
  font-size: 12px;
  position: absolute;
  top: 100%;
  padding-top: 5px;
  transform: translateX(-50%);
}

.hd-histogram .__x-axis-bar-val-lower {
  left: 0;
}

.hd-histogram .__x-axis-bar-val-upper {
  left: 100%;
}

.hd-histogram .__x-axis-bar-bound::after {
  content: " ";
  height: 5px;
  width: 1px;
  background-color: black;
  display: block;
  position: absolute;
  right: 50%;
  top: 0;
}

.hd-histogram .__x-axis-bar-val {
  font-size: 14px;
  position: absolute;
  bottom: 100%;
  width: 100%;
  text-align: center;
}

.hd-histogram .__x-axis-bar-val.__inset {
  bottom: unset;
  top: 5px;
  color: white;
}

.hd-histogram .__x-axis-title {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bolder;
}

.hd-histogram .__y-axis-title {
  position: absolute;
  bottom: 50%;
  left: -30px;
  transform: translateY(-50%) rotate(-90deg);
  font-weight: bolder;
}

.hd-fgv {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.hd-fgv__menu {
  background-color: #b92028;
  padding: 1.2em 0 0.9em 0;
}

.hd-fgv__menu__logo::after,
.hd-fgv__menu::after {
  content: " ";
  display: block;
  clear: both;
}

.hd-fgv__menu__logo {
  float: left;
}

.hd-fgv__menu__logo img {
  height: 1em;
  padding: 1em 0;
}

.hd-fgv__menu__logo img,
.hd-fgv__menu__logo__separator {
  float: left;
}

.hd-fgv__menu__logo__separator {
  padding: 1em 0.5em 0 0.5em;
}

.hd-fgv__menu__logo__separator::after {
  content: " ";
  color: white;
  font-weight: bolder;
}

.hd-fgv__menu__content {
  float: right;
  margin-top: 0.2em;
}

.hd-fgv__menu__content__item {
  color: white;
  text-decoration: none;
  border: solid 1.8px transparent;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.35em 1em;
  border-radius: 1em;
  margin-left: 1.2em;
  cursor: pointer;
  font-size: 0.9em;
}

.hd-fgv__menu__content__item--featured {
  border-color: white;
}

.hd-fgv__menu__content__item:hover,
.hd-fgv__menu__content__item--active {
  background-color: white;
  color: #b92028;
}

.hd-fgb__upgrade-access-bar {
  background-color: lightgray;
  padding: 5px 0;
  text-align: center;
}

.hd-fgb__upgrade-access-bar_link {
  color: darkblue;
  font-weight: bolder;
  cursor: pointer;
  text-decoration: underline;
}

.hd-fgv__top-part {
  text-align: center;
  padding-top: 3.9em;
  font-size: 0.9em;
}

.hd-fgv__top-part__title {
  color: #b92028;
  font-size: 2.36em;
  padding: 0;
  margin: 0 0 0.28em 0;
}

.hd-fgv__top-part__description {
  margin: 0;
  padding: 0;
  font-size: 1.4em;
  margin-bottom: 1.5em;
}

.hd-fgv__top-part__form {
  margin: 0 auto;
  border: solid 1px gray;
  width: 37em;
  border-radius: 2em;
  overflow: hidden;
  padding: 0.2em;
}

.hd-fgv__top-part__form:after {
  content: " ";
  clear: both;
  display: block;
}

.hd-fgv__top-part__form input {
  border: 0;
  outline: 0;
  text-align: left;
  padding: 0.55em 0.9em 0.35em 0.9em;
  display: block;
  font-size: 1.1em;
  float: left;
  font-family: inherit;
  width: 54%;
  box-sizing: border-box;
}

.hd-fgv__top-part__form button {
  float: right;
  background-color: #b92028;
  border: 0;
  color: white;
  font-size: 1.1em;
  height: 2.4em;
  border-radius: 2em;
  width: 46%;
  font-family: inherit;
  box-sizing: border-box;
  line-height: 2.5em;
}

.hd-fgv__top-part__obs {
  font-size: 0.85em;
  margin: 0;
  padding: 1.1em 0;
}

.hd-fgb__body {
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding-bottom: 2em;
}

.hd-fgb__body iframe {
  border: 0;
}

.fgvr {
  height: 100vh;
  background: rgba(243, 243, 243, 1);
}

.fgvr::before {
  content: " ";
  display: inline-block;
}

.fgvr__center {
  text-align: center;
  margin-top: 15vh;
}

.fgvr__logo img {
  display: block;
  margin: 0 auto;
}

.fgvr__logo img:nth-child(1) {
  height: 35px;
}

.fgvr__logo img:nth-child(2) {
  margin-top: 10px;
  height: 20px;
}

.fgvr__form {
  background-color: white;
  width: 500px;
  margin: 20px auto;
  padding: 10px;
  border: solid 1px gray;
}

.fgvr__form p {
  margin: 10px 0 15px 0;
  padding: 0;
}

.fgvr__form::before {
  content: " ";
  display: table;
}

.fgvr__input {
  width: 100%;
  box-sizing: border-box;
  border: 0;
  padding: 9px;
  margin-bottom: 10px;
  font-size: 16px;
  outline: none;
  background-color: #ebebeb;
}

.fgvr__btn {
  margin: 10px 0 5px 0;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bolder;
  border: 0;
  background-color: rgba(191, 30, 46, 1);
  color: white;
  cursor: pointer;
}

.fgvr__btn:hover {
  background-color: rgb(91 25 32);
}

.fgvr__btn:disabled {
  opacity: 0.5;
}

.fgvr__label {
  font-size: 12px;
  font-weight: bolder;
}

.fgvr__input-wrapper {
  text-align: left;
}

.hd-fgv__section-1 {
}

.hd-embedded-dash__center {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.hd-embedded-dash__center div:nth-child(1) {
  font-size: 40px;
  font-weight: bolder;
  text-transform: uppercase;
  border-bottom: solid 1px black;
  width: 300px;
  margin: 0 auto 20px auto;
}

.hd-embedded-dash__center div:nth-child(2) {
  font-size: 20px;
  margin-bottom: 10px;
}

.hd-embedded-dash__center div:nth-child(3) {
  font-size: 50px;
  transform: rotate(90deg);
  margin-bottom: 20px;
  display: inline-block;
}

.hd-embedded-dash__btn {
  padding: 10px 20px;
  font-weight: bolder;
  background-color: #b92028;
  border: 0;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
}

.hd-embedded-dash__btn:disabled {
  cursor: default;
  opacity: 0.5;
}

.hd-embedded__target {
  height: 100%;
}

.fgv-content {
  padding: 10px;
}

.fgv-content h1 {
  border-bottom: solid 1px black;
  padding: 0 0 5px 0;
  margin: 0;
  font-size: 30px;
}

.h-privacy-page {
  background-color: #f3f3f3;
  height: 100vh;
}

.h-privacy-page-header {
  background-color: #bf1e2e;
  box-shadow: 0 2px 3px 1px rgba(0, 0, 0);
  text-align: center;
  padding: 15px 0 10px 0;
}

.h-privacy-page-header img {
  height: 30px;
}

.h-privacy-page-content {
  max-width: 95%;
  width: 600px;
  margin: 0 auto;
  height: calc(100vh - 170px);
  overflow: auto;
  font-family: "Times New Roman", Times, serif;
  text-align: justify;
  background-color: white;
  padding: 0 20px 20px 20px;
  border: solid 1px gray;
}

.h-privacy-page-content-full-page {
  height: auto;
  width: 100%;
  border: 0;
}

.h-privacy-page-content h1 {
  text-transform: uppercase;
  text-align: center;
  font-size: 26px;
}

.h-privacy-page-content h2 {
  text-transform: uppercase;
  font-size: 18px;
}

.h-privacy-page-nav {
  margin: 12px 0 10px 0;
  text-align: center;
}

.h-privacy-page-nav a {
  color: blue;
  cursor: pointer;
}

.h-privacy-page-nav a:hover {
  text-decoration: underline;
}

.h-privacy-footer {
  text-align: center;
  font-size: 14px;
  margin-top: 7px;
  line-height: 14px;
  color: #2d2d2d;
}

.h-privacy-footer-title {
  font-weight: bolder;
}

.hd-fgv__extra {
  color: #414042;
}

.hd-fgv__content {
  width: 66.5em;
  margin: 0 auto;
}

.hd-fgv__section-1 {
  background-color: #eeeeee;
  position: relative;
}

.hd-fgv__title {
  text-align: center;
  margin: 0;
  padding: 0.5em;
  font-size: 1.8em;
  margin-bottom: 1em;
}

.hd-fgv__section-1 .hd-fgv__title {
  color: #414042;
  font-size: 2.1em;
  padding: 0;
  margin-top: 1.3em;
  margin-bottom: 0.6em;
}

.hd-fgv__section-1__row:after {
  clear: both;
  content: " ";
  display: block;
}

.hd-fgv__section-1__text {
  text-align: center;
  font-size: 1.2em;
  line-height: 1.7em;
  margin: 0 -0.5em 0.5em -0.5em;
}

.hd-fgv__section-1__col {
  width: 33.3333333%;
  float: left;
  margin: 2em 0;
}

.hd-fgv__section-1__item__text {
  text-align: center;
  margin: 1.3em 0 0 0;
  font-size: 1.2em;
}

.hd-fgv__section-1__item__img {
  display: block;
  margin: 0 auto;
  height: 4em;
}

.hd-fgv__section-1__link-1-wrapper {
  text-align: center;
  margin-top: 1em;
}

.hd-fgv__link {
  display: block;
  color: #be1e2d;
}

.hd-fgv__section-1__decoration-1 {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  border-top: 2em solid transparent;
  border-bottom: 2em solid transparent;
  border-right: 50vw solid #cecece;
  transform: translateY(-50%);
}

.hd-fgv__section-1__decoration-2 {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-top: 2em solid transparent;
  border-bottom: 2em solid transparent;
  border-left: 50vw solid #eeeeee;
  transform: translateY(-50%);
}

.hd-fgv__section-2 {
  padding: 2em 0;
}

.hd-fgv__section-2__sub-title {
  color: #be1e2d;
  font-size: 1.5em;
  font-weight: bolder;
}

.hd-fgv__section-2__item {
  text-align: center;
  font-size: 1.1em;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 2.5em;
}

.hd-fgv__section-1__item__img {
  height: 4.5em;
}

.hd-fgv__section-2__text-part {
  font-size: 1.2em;
  text-align: left;
  width: 50%;
}

.hd-fgv__section-2__text-part-2 {
  position: relative;
  top: -1.4em;
  left: -2.4em;
}

.hd-fgv__section-2__image-part {
  height: 20em;
  display: inline-block;
  width: 50%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.hd-fgv__section-2__text-wrapper {
  font-size: 0.8em;
  margin-top: 0.8em;
}

.hd-fgv__section-2__image-part-1 {
  background-position: 0em 0.4em;
  height: 23.1em;
  position: relative;
  left: -3.8em;
}

.hd-fgv__section-2__image-part-2 {
  height: 29em;
  position: relative;
  left: -2.3em;
}

.hd-fgv__section-2__text {
  margin-top: 1em;
}

.hd-fgv__section-2__spacer {
  display: inline-block;
  width: 3em;
  vertical-align: middle;
}

.hd-fgv__pricing__table-wrapper {
  -webkit-box-shadow: 0px 0px 9px 3px #aaaaaa;
  box-shadow: 0px 0px 9px 3px #aaaaaa;
  border-radius: 1em;
  padding: 2em;
  margin: 2em 0;
  font-size: 0.9em;
}

.hd-fgv__pricing .hd-fgv__title {
  margin-top: 0.3em;
}

.hd-fgv__pricing__table {
  width: 100%;
  border-collapse: collapse;
}

.hd-fgv__pricing__table th:first-child,
.hd-fgv__pricing__table td:first-child {
  width: auto;
}

.hd-fgv__pricing__table th,
.hd-fgv__pricing__table td {
  width: 10em;
}

.hd-fgv__pricing__table tbody tr:nth-child(2n + 1) {
  background-color: #eeeeee;
}

.hd-fgv__pricing__table td,
.hd-fgv__pricing__table th {
  padding: 0.75em;
  text-align: center;
}

.hd-fgv__pricing__table th {
  color: #be1e2d;
}

.hd-fgv__pricing__links {
  background-color: white;
}

.hd-fgv__pricing__links a {
  color: #be1e2d;
  font-weight: bolder;
  font-size: 0.9em;
}

.hd-fgv__pricing__table td:first-child,
.hd-fgv__pricing__table th:first-child {
  text-align: left;
  color: black;
}

.hd-fgv__pricing__feature {
  background-image: url("./assets/img/feature-disabled.png");
  height: 1.2em;
  width: 1.2em;
  background-size: contain;
  display: inline-block;
}

.hd-fgv__pricing__feature--active {
  background-image: url("./assets/img/feature-active.png");
}

.hd-fgv__pricing__feature--active-dark {
  background-image: url("./assets/img/feature-active-dark.png");
}

.hd-fgv__on-media .hd-fgv__title {
  color: #be1e2d;
  font-size: 1.5em;
}

.hd-fgv__on-media__row {
  margin: 0 auto;
  width: 80%;
  position: relative;
}

.hd-fgv__on-media__row:after {
  clear: both;
  content: " ";
  display: block;
}

.hd-fgv__on-media__col {
  width: 50%;
  float: left;
  padding: 0 2em;
  box-sizing: border-box;
}

.hd-fgv__on-media__item {
  height: 28em;
  background-size: 100% 100%;
  -webkit-box-shadow: 0px 0px 9px 3px #aaaaaa5e;
  box-shadow: 0px 0px 9px 3px #aaaaaa5e;
  border-radius: 1em;
}

.horus__slider__control {
  top: 49.2%;
  position: absolute;
  border-radius: 50%;
  width: 2.8em;
  height: 2.8em;
  background-color: white;
  display: none;
  -webkit-box-shadow: 0px 0px 15px 1px #949494;
  box-shadow: 0px 0px 15px 1px #949494;
  cursor: pointer;
}

.horus__slider__control--active {
  display: block;
}

.horus__slider__control:hover {
  background-color: gray;
}

.horus__slider__control__left {
  left: 2em;
  transform: translate(-62.9%, -50%);
}

.horus__slider__control__right {
  right: 2em;
  transform: translate(62.9%, -50%) rotate(180deg);
}

.horus__slider__control > div:nth-child(1) {
  top: 38%;
  transform: rotate(-45deg);
}

.horus__slider__control > div:nth-child(2) {
  bottom: 37%;
  transform: rotate(45deg);
}

.horus__slider__control > div {
  width: 0.8em;
  height: 0.2em;
  background-color: #8f8f8f;
  position: absolute;
  left: 30%;
}

.horus__slider__control:hover > div {
  background-color: white;
}

.hd-fgv__faq {
  padding: 2em;
}

.hd-fgv__faq a {
  color: #be1e2d;
  font-weight: bolder;
}

.hd-fgv__faq-item {
  padding: 1em;
  border: solid 1px gray;
}

.hd-fgv__faq-title {
  font-weight: bolder;
  position: relative;
  cursor: pointer;
}

.hd-fgv__faq-description {
  padding-top: 1em;
}

.hd-fgv__faq-item__expand {
  position: absolute;
  right: 0;
  top: 0.2em;
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

.hd-fgv__faq-item__expand:hover:after,
.hd-fgv__faq-item__expand:hover:before {
  background-color: black;
}

.hd-fgv__faq-item__expand:after {
  position: absolute;
  top: 0.4em;
  left: 0.4em;
  display: block;
  content: " ";
  height: 60%;
  width: 15%;
  background-color: gray;
  transform: rotate(-45deg);
}

.hd-fgv__faq-item__expand:before {
  position: absolute;
  top: 0.4em;
  right: 0.4em;
  display: block;
  content: " ";
  height: 60%;
  width: 15%;
  background-color: gray;
  transform: rotate(45deg);
}

.hd-fgv__faq-description-wrapper {
  display: none;
}

.hd-fgv__faq-item--open .hd-fgv__faq-item__expand {
  transform: rotate(180deg);
}

.hd-fgv__faq-item--open .hd-fgv__faq-description-wrapper {
  display: block;
}

.hd-fgv__section-2__image-part-3 {
  height: 25em;
  position: relative;
  right: 4em;
  bottom: 1em;
}

.hd-fgv__pricing__table thead tr:nth-child(2) th {
  color: black;
  padding: 0;
}

.hd-fgv__pricing__table thead tr:nth-child(3) th {
  color: gray;
  font-size: 0.8em;
  padding: 1em 0 0.5em 0;
}

.hd-fgv__pricing__table thead tr a,
.hd-fgv__pricing__table thead tr button {
  background-color: #be1e2d;
  color: white;
  text-decoration: none;
  padding: 0.4em 0.9em;
  border-radius: 1em;
  font-weight: normal;
  border: 0;
}

.hd-fgv__pricing__table .__small {
  font-size: 0.9em;
  font-weight: normal;
}

.hd-fgv__pricing {
  margin-top: -2em;
}

.hd-fgv__footer {
  background-color: #b92028;
}

.hd-fgv__footer__row {
  display: flex;
  align-items: flex-start;
}

.hd-fgv__footer__col {
  width: 33.3333%;
  padding: 5em 0;
}

.hd-fgv__footer__horus-logo,
.hd-fgv__footer__fgv-logo {
  width: 15em;
  margin-bottom: 2em;
}

.hd-fgv__footer h3 {
  margin: 0 0 1em 0;
  color: white;
}

.hd-fgv__footer nav ul {
  margin: 0;
  padding: 0;
}

.hd-fgv__footer nav ul li {
  list-style: none;
  display: flex;
  justify-self: left;
}

.hd-fgv__footer nav a {
  color: white;
  text-decoration: none;
  padding: 4px 0;
}

.hd-fgv__footer nav a:hover {
  text-decoration: underline;
}

.hd__modal__back {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
}

.hd__modal__body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  background-color: white;
  border-radius: 1em;
}

.hd__modal__open-enter {
  display: block;
  opacity: 0;
}

.hd__modal__open-enter-active {
  transition: 0.5s;
  opacity: 1;
}

.hd__modal__open-enter-done {
  display: block;
  opacity: 1;
}

.hd__modal__open-exit {
  display: block;
  opacity: 1;
}

.hd__modal__open-exit-active {
  transition: 0.5s;
  opacity: 0;
}

.hd__modal__open-exit-done {
  display: none;
  opacity: 0;
}

.hd__modal__open-enter .hd__modal__body {
  top: 45%;
  opacity: 0;
}

.hd__modal__open-enter-active .hd__modal__body {
  transition: 0.5s;
  opacity: 1;
  top: 50%;
}

.hd__modal__open-enter-done .hd__modal__body {
  top: 50%;
  opacity: 1;
}

.hd__modal__open-exit .hd__modal__body {
  top: 50%;
  opacity: 1;
}

.hd__modal__open-exit-active .hd__modal__body {
  transition: 0.5s;
  opacity: 0;
  top: 45%;
}

.hd__modal__open-exit-done .hd__modal__body {
  opacity: 0;
}

.hd__modal__body-close {
  font-family: inherit;
  font-weight: bolder;
  padding: 0.03em 0.35em;
  border: 0;
  background-color: transparent;
  font-size: 1.4em;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  cursor: pointer;
  border-radius: 50%;
  transform: rotate(45deg);
  -webkit-user-select: none;
  user-select: none;
}

.hd__modal__body-close:hover {
  background-color: gray;
  color: white;
}

.hd-fgv__register__text {
  text-align: center;
  padding: 1em 0;
  width: 25em;
}

.hd-fgv__register__input {
  font-size: 1.2em;
  display: block;
  margin: 0 auto 0.5em auto;
  padding: 0.5em 1em;
  width: 100%;
  border-radius: 1em;
  border: solid 1px gray;
  outline: none;
  box-sizing: border-box;
}

.hd-fgv__register__input:focus {
  border-color: black;
}

.hd-fgv__register__btn {
  display: block;
  margin: 1.5em auto 1em auto;
  background-color: #b92028;
  border: 0;
  font-family: inherit;
  font-size: 1em;
  color: white;
  padding: 0.5em 1.5em;
  border-radius: 1em;
  font-weight: bolder;
  cursor: pointer;
}

.hd-fgv__register__btn[disabled] {
  background-color: gray !important;
  cursor: default;
}

.hd-fgv__register__btn:hover {
  background-color: #8d1a20;
}

.hd-fgv__categories-list .hd-fgv__title {
  margin: 0 0 0.5em 0;
  font-size: 1.4em;
}

.hd-fgv__categories-list ul {
  column-count: 3;
  list-style: none;
  margin: 0;
  padding: 0;
}

.hd-fgv__plus__top-part__button {
  text-decoration: none;
  background-color: #b92028;
  color: white;
  padding: 0.5em 1em;
  border-radius: 2em;
  font-size: 1.4em;
  font-weight: bolder;
  border: 0;
  display: inline-block;
}

.hd-fgv__plus__top-part__button:hover {
  background-color: #8d1a20;
  cursor: pointer;
}

.hd-fgv__plus__top-part__separator {
  padding: 0.5em 0 0.3em 0;
  font-size: 1.5em;
}

.hd-fgv__plus__top-part__button-wrapper button {
  background-color: transparent;
  border: 0;
  color: #b92028;
  font-weight: bolder;
  font-size: 1.2em;
  text-decoration: underline;
  padding: 0;
  margin-bottom: 1.5em;
  cursor: pointer;
}

.hd-fgv__privacity {
  padding-bottom: 2em;
}

.hd-fgv__important .hd-fgv__content {
  border: 2px solid #b92028;
  padding: 16px 32px;
  width: calc(66.5em - 64px);
  margin-top: 32px;
  margin-bottom: 64px;
  text-align: center;
}

.hd-fgv__important .hd-fgv__content span {
  color: #b92028;
  font-weight: 500;
}

.h-sumw__email-confirm__wrapper {
  width: 400px;
  text-align: center;
}

.h-sumw__email-confirm__wrapper input {
  text-align: center;
  border: 0;
  border-bottom: solid 1px gray;
  margin: 10px 0 5px 0;
  padding: 5px;
  width: 70px;
  outline: none;
}

.h-sumw__email-confirm__wrapper input:focus {
  border-color: black;
}

.h-sumw__email-confirm__countdown {
  font-weight: bolder;
}

.h-sumw__email-confirm__buttons {
  margin-top: 20px;
}

.h-sumw__email-confirm__buttons button {
  font-weight: bolder;
  text-transform: uppercase;
  border: 0;
  padding: 5px 10px;
  background-color: transparent;
  cursor: pointer;
}

.h-sumw__email-confirm__buttons button:hover {
  background-color: lightgray;
}

#HomeDashboard .hd-dash-content {
  min-height: unset;
}

#HomeDashboard .hd-pbi-btn {
  background: unset;
  border: unset;
  padding: 10px;
  display: flex;
  margin: 6px 6px;
}

#HomeDashboard .hd-pbi-btn:hover {
  background: #fff;
  max-width: 16px;
}

#HomeDashboard .hd-pbi-btn i {
  font-size: 16px;
}

.hdmsps-filter-box {
  padding: 0.5em;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px#e5e5e5;
  color: #414042;
  outline: none;
}
