.wrapper {
  inset: 0;
  background: #00000050;
  position: fixed;
}
.wrap {
  inset: 0;
  background: #ffffff;
  position: fixed;
  display: flex;
  box-shadow: 0px 0px 9px 3px #aaaaaa;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 600px;
  margin: auto;
  max-height: max-content;
  overflow-x: auto;
  height: max-content;
}

.wrap * {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.wrap svg {
  padding: 16px 0 8px 0;
}

.wrap h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #282828;
  margin: 0;
}

.wrap header p {
  font-size: 14px;
  color: #4d4a4a;
}

.wrap input[type="button"] {
  padding: 8px 16px;
  margin: 4px 2px;
  border: 0;
  background: #e0e0e0;
  color: #1c1c1c;
  cursor: pointer;
  border-radius: 5px;
  border: 1.5px solid #28282800;
}
.active {
  border: 1.5px solid #282828 !important;
}

.wrap .values input:nth-child(1) {
  background-color: rgba(191, 30, 46, 0.55) !important;
}

.wrap .values input:nth-child(1) {
  background-color: rgba(191, 30, 46, 0.55) !important;
}

.wrap .values input:nth-child(2) {
  background-color: rgba(191, 30, 46, 0.6) !important;
}

.wrap .values input:nth-child(3) {
  background-color: rgba(191, 30, 46, 0.65) !important;
}

.wrap .values input:nth-child(4) {
  background-color: rgba(191, 30, 46, 0.7) !important;
}

.wrap .values input:nth-child(5) {
  background-color: rgba(191, 30, 46, 0.75) !important;
}

.wrap .values input:nth-child(6) {
  background-color: rgba(191, 30, 46, 0.8) !important;
}

.wrap .values input:nth-child(7) {
  background-color: rgba(191, 30, 46, 0.85) !important;
}

.wrap .values input:nth-child(8) {
  background-color: rgba(191, 30, 46, 0.9) !important;
}

.wrap .values input:nth-child(9) {
  background-color: rgba(191, 30, 46, 0.95) !important;
}

.wrap .values input:nth-child(10) {
  background-color: rgba(191, 30, 46, 1) !important;
}

.wrap .values {
  display: flex;
  width: 100%;
  padding: 16px 0;
  flex-wrap: wrap;
  justify-content: center;
}

.wrap .value {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: #bf1e2e !important;
  color: #ffffff !important;
}

.wrap textarea {
  background: #efefef;
  border: 1px solid #efefef;
  box-sizing: border-box;
  padding: 8px;
  min-height: 64px;
  width: 100%;
}

:is(.wrap header, .wrap main, .wrap footer) {
  width: calc(100% - 32px);
  text-align: center;
}

.wrap footer {
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .wrap footer {
    display: contents;
  }
}

.wrap .actions {
  padding: 8px 12px;
  margin: 8px;
  border: 0;
  border-color: #e0e0e0;
  color: #1c1c1c;
  cursor: pointer;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #e0e0e0;
  color: #7e7e7e;
  cursor: pointer;
}

.wrap .send {
  background: #42962d;
  color: #FFF;
  cursor: pointer;
}

.wrap .send:disabled {
  background: gray;
  color: #FFF;
  cursor: auto;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  font-family: inherit;
  font-weight: bolder;
  padding: 0.03em 0.35em;
  border: 0;
  background-color: #fff;
  font-size: 1.4em;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  transform: rotate(45deg);
  -webkit-user-select: none;
  user-select: none;
  font-size: 13px;
  margin: 16px;
}

.close:hover {
  background-color: gray;
}

.close span {
  transform: rotate(136deg);
  display: flex;
  padding: 4px;
  font-size: 13px;
  font-weight: 600;
}

.autoClose {
  inset: 0;
  position: fixed;
}
