
.content {
    min-height: 100vh;
    transition: .5s;
}

.content::before {
    content: '';
    background-color: var(--main-color);
    height: 50vh;
    width: 100%;
    display: block;
    position: absolute;
    z-index: -1;
    min-height: 358px;
}

.loading {
    font-size: 50px;
    margin: -25px 0 50px 48.5%;
}

#HomeDashboard .hd-dash-menu .hd-pbi-btn {
    display: none!important;
    overflow: hidden!important;
}


#HomeDashboard  .hd-dash-backdrop {
    background-color: unset;
}


.menu ul {
    display: flex;
    list-style-type: none;
    flex-direction: column;
    padding: 0;
    border-bottom: 1px solid white;
    padding-bottom: 8px;
}

.menu ul button i {
    padding: 0 8px 0 0;
}

.menu ul button {
    background: unset;
    border: unset;
    background: unset;
    border: unset;
    color: #fff;
    cursor: pointer;
}


