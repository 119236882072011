    /* .wrap */
    .wrap {
        min-height: auto;
        background: #bd1e2c;
        margin-top: 62px;
    }
    .wrap h2 {
        font-size: 32px;
        padding: 16px 0;
    }
    .wrap:is(h2, p, a) {
        color: #fff;
        max-width: 350px;
    }
    .wrap .container {
        max-width: 960px;
        display: flex;
        justify-content: space-between;
    }
    .wrap > .container .col {
        width: 50%;
        padding: 16px 0;
    }
    .wrap form {
        display: flex;
        flex-direction: column;
    }
    .wrap form input {
        width: 50%;
        min-width: 70px;
    }
    .wrap form > div {
        display: flex;
        flex-direction: revert;
        flex-wrap: wrap;
    }
    .wrap form > div input {
        width: 100%;
        max-width: calc(230px - 8px);
        height: 40px;
        background: #bd1e2c;
        border: 1px solid #fff;
        padding: 0 6px;
        color: #FFF;
    }
    .wrap form div {
        display: flex;
        justify-content: space-between;
        padding: 8px 4px;
        width: 100%;
    }
    .wrap form:is(input, textarea)::placeholder {
        color: #FFF;
    }
    .wrap form textarea {
        width: calc(100% - 14px);
        margin: 0 4px;
        height: 126px;
        background: #bd1e2c;
        border: 1px solid #fff;
        padding: 6px;
        color: #fff;
    }
    .wrap form button {
        padding: 4px;
        width: 100%;
        margin: 8px 4px;
        height: 40px;
        align-items: center;
        background: #fff;
        color: #bd1e2c;
        font-weight: bold;
        cursor: pointer;
    }
    .wrap > div:nth-child(1) > img {
        width: 100px;
        height: 40px;
        object-fit: contain;
    }
    .wrap > div:nth-child(1) ul {
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        padding: 12px;
        margin: 0;
    }
    .wrap > div:nth-child(1) ul li a {
        font-size: 14px;
        text-decoration: none;
        padding: 0 8px;
    }
    .wrap ul li img {
        width: 24px;
    }
    .wrap > div:nth-child(1) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding-top: 12px;
    }
    .wrap div span {
        padding: 12px;
        color: #d0737a;
        font-size: 13px;
    }

    .wrap * {
        color: #fff;
    }