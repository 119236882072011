.wrapdbs {
    inset: 0;
    position: absolute;
    background-color: #BF1E2E;
    color: #FFF;
    min-height: 100%;
}


.wrapdbs main, .wrapdbs header {
    max-width: 390px;
    margin: auto;
}

.wrapdbs header h1 img {
    max-width: 150px;
}

.wrapdbs header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapdbs main > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
} 

.wrapdbs main > div > img {
    padding: 8px 0;
    max-width: 150px;
}

.wrapdbs main h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    margin: 0;
}

.wrapdbs main p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.wrapdbs main button {
    background: #CF2132;
    box-shadow: 4px 4px 4px rgb(0 15 49 / 8%);
    border-radius: 8px;
    border: 0;
    padding: 16px 98px;
    margin: 16px 0;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
}


.wrapdbs main a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    align-items: center;
    display: flex;
}

.wrapdbs main button {
    display: flex;
    align-items: center;
}

.wrapdbs main a img {
    padding: 0px 10px;
    object-fit: revert;
    width: 18px;
}

.wrapdbs main button img  {
    padding: 0px 12px;
    margin-top: -5px;
    position: relative;
}


.wrapdbs ul {
    margin: 0;
    padding: 12px 0;
}

.wrapdbs li {
    list-style: none;
    padding: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

}

.wrapdbs .qrcode {
    padding: 12px 0;
}

.wrapdbs .wrapdbs {
    background-image: url('./assets/bg.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 350px auto;
}


