:root {
  --color-primary: #072e43;
  --red: #F0000A;
  --color-white: #fff;
}

.hd-fgv__top-part__form span:nth-child(1) {
  display: block;
}

.hd-fgv__top-part__form span:nth-child(2) {
  display: block;
}

button.hd-fgv__menu__content__item {
  background: #b92028;
}

.hd-fgv__hero .content p.hd-fgv__top-part__obs {
  font-size: 13px;
  padding-left: 5px;
  color: #414042;
}

div#sobre{
  padding-top: 62px;
}


#plataforma-horus {
  top: -120px;
  position: relative;
}




@media (max-width: 480px) {
  .hd__modal__open-enter-done .hd__modal__body *,
  .hd__modal__body,
  .hd-fgv__register__text {
    max-width: 240px !important;
  }
  .hd-fgv__hero .content ul {
    display: flex;
    list-style: none;
    padding: 0;
    flex-wrap: unset;
}
  .hd-fgv__top-part__form {
    min-width: 32px;
  }
  .hd-fgv__top-part__form span:nth-child(1) {
    display: none;
  }

  .hd-fgv__categories-list ul {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;
  }
}

.hd-fgv__menu {
  position: fixed;
  width: 100%;
  z-index: 9999999;
  top: 0;
}

 div.hd-fgv__menu > div > nav > button:nth-child(4) {
  background: #fff!important;
  color: #b92028;
}

.hd-fgv__hero .hd-fgv__top-part__form {

  margin: 0;
  background: #fff;
}

.hd-fgv__content {
  width: 100%;
  max-width: 960px;
}

.hd-fgv__hero .content {
  max-width: 960px;
  margin: auto;
  padding: 16px 16px;
  height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  width: calc(100% - 64px);
}

.hd-fgv__on-media .hd-fgv__title {
  padding: 32px 0 16px 0;
}

.hd-fgv__hero .content img {
  width: 350px;
}

.hd-fgb__body-max-height {
  max-height: 100%;
  min-height: 100%;
  height: 100%;
}


@media (max-width: 780px) {
  .hd-fgv__content {
    width: calc(100% - 32px);
  }

  .hd-fgv__menu__logo {
    float: unset;
    display: flex;
    justify-content: center;
  }

  .hd-fgv__menu__content {
    float: unset;
    margin-top: 0.2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;
  }

  .hd-fgb__body {
    max-height: 340px;
  }

  .hd-fgv__section-2__text-part {
    min-width: 270px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: calc(80% - 32px);
    left: unset;
    top: unset;
  }

  .hd-fgv__section-2__image-part {
    min-width: 270px;
    left: unset;
    right: unset;
    height: 260px;
    padding: 16px 0;
    background-position: inherit;
    background-size: contain;
    width: 80%;
    background-position: bottom;
    margin: 32px 0;
  }

  .hd-fgv__section-2__item.hd-fgv__section-2__item--inverted {
    flex-direction: column;
    padding: 32px 0;
  }
  .hd-fgv__section-2__item {
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .hd-fgv__footer__col {
    width: 33.3333%;
    padding: 16px;
    min-width: 270px;
  }

  .hd-fgv__top-part__form {
    width: 100%;
  }

  .hd-fgv__footer__row {
    display: flex;
    flex-wrap: wrap;
  }

  .hd-fgv__footer__horus-logo,
  .hd-fgv__footer__fgv-logo {
    width: 15em;
    padding: 16 0;
    margin-bottom: 0;
  }

  .hd-fgv__footer__col:nth-child(1) {
    padding-top: 32px;
  }

  .hd-fgv__footer__col:nth-child(1) img {
    padding: 8px 0;
  }

  .hd-fgv__important {
    width: calc(100% - 32px);
    margin: auto;
  }

  .hd-fgv__on-media__col {
    width: 100%;
    margin: 16px 0;
    max-width: 392px;
  }

  .hd-fgv__pricing__table-wrapper {
    overflow: auto;
    box-shadow: none;
  }

  .hd-fgv__section-1__col {
    min-width: 100%;
  }

  .hd-fgv__pricing__table thead tr a {
    min-width: 80px;
    display: flex;
    justify-content: center;
  }

  .hd-fgv__section-1__text {
    padding: 0 32px;
  }

  .hd-fgv__on-media__row {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hd-fgv__faq-title {
    font-weight: bolder;
    position: relative;
    padding-right: 20px;
  }

  .hd-fgv__hero .content {
      max-width: calc(960px - 32px);
      margin: auto;
      padding: 16px 16px;
      display: flex;
      align-items: center;
      padding-left: 0;
      padding-right: 0;
      justify-content: center;
      flex-flow: wrap-reverse;
      height: auto;
      margin: 0 32px;
  }

  .hd-fgv__hero {
    min-height: 600px;
    background: #fde9ee;
    display: flex;
    align-items: center;
}

.hd-fgv__counter {
  margin-top: 70px;
}
.hd-fgv__hero .content img {
  width: 250px;
}
}

.hd-fgv__hero .content h2 {
  font-size: 32px!important;
}

@media (max-width: 490px) {

  .hd-fgv__hero .content h2 {
      font-size: 21px!important;
  }
}

.hd-fgv {
  position: relative;
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

.break-scroll {
  position: absolute;
  top: -162px;
}

.small-break-scroll {
  position: absolute;
  top: -80px;
}

.fgv-bg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.fgv-container {
  width: 100%;
  max-width: 1280px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.fgv-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hd__modal__body {
  min-width: 400px;
  max-width: 800px;
  box-sizing: border-box;
  padding:0 32px 20px;
}

.hd__modal__body-close {
  color: var(--red);
  font-size: 20px;
  width: 30px;
  height: 30px;
}

.hd__modal__body-close:hover {
  background: var(--red);
}

.hd__modal__body h1 {
  width: 100%;
  color: #00B4F0;
  font-size: 20px;
  margin: 0;
  text-align: center;
  font-weight: 600;
  margin: 16px 0 0;
}

.input-container label {
  font-size: 12px;
}

.hd-fgv__register__text {
  width: 100%;
  text-align: center;
  padding: 0 36px;
  box-sizing: border-box;
  color: var(--color-primary);
  font-size: 16px;
  margin-top: 0;
}

.hd-fgv__register__input {
  background: #F4F8FA;
  border-color: #CAD6E2;
  border-radius: 12px;
}

.hd-fgv__register__btn {
  background: var(--color-primary);
  font-size: 14px;
  font-weight: 400;
  padding: 12px 36px;
  border-radius: 30px;
  margin: 12px 0 0;
  float: right;
}

.fgv-header {
  min-height: 64px;
  position: fixed;
  padding: 34px 0 8px;
  top: 0;
  left: 0;
  transition: 0.1s;
  z-index: 99;
}

.fgv-header_container {
  align-items: center;
  justify-content: space-between;
  gap: 58px;
}

.fgv-header_scrolling {
  background: #01263C;
}

.fgv-header-right {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 44px;
}

.fgv-header-right > ul {
  display: flex;
  gap: 28px;
}

.fgv-header-right > ul > li {
  list-style: none;
}

.fgv-header-right > ul > li > a {
  padding: 0 16px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  transition: 0.4s;
}

.fgv-header-right > ul > li > a:hover {
  text-decoration: underline;
}

.fgv-signup {
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 0 36px;
  cursor: pointer;
  transition: 0.4s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fgv-signup:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.fgv-signup:disabled {
  cursor: not-allowed;
  background: transparent;
  border-color: var(--color-white);
}

section.fgv-hero {
  padding-top: 280px;
  color: #fff;
}

section.fgv-hero .fgv-container {
  align-items: flex-start;
}


.fgv-hero_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.fgv-hero_column h1 {
  font-size: 52px;
  letter-spacing: -1%;
  line-height: 58px;
  margin: 0;
}

.fgv-hero_column p {
  font-size: 20px;
  margin: 0;
  font-weight: 400;
}

.fgv-auxiliary-nav {
  width: 100%;
  max-width: 1440px;
  height: 64px;
  background: #fff;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 8px 24px rgba(0,0,0,0.1);

  margin-top: 80px;
  margin-bottom: 56px;
}

.fgv-auxiliary-nav > ul {
  display: flex;
  gap: 40px;
}

.fgv-auxiliary-nav > ul > li {
  list-style: none;
}

.fgv-auxiliary-nav > ul > li > a {
  padding: 0 16px;
  color: #121212;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  transition: 0.4s;
}

.fgv-auxiliary-nav > ul > li > a:hover {
  text-decoration: underline;
}

.hd-fgv_pricing {
  width: 100%;
  background: linear-gradient(180deg, rgba(248,248,248,1) 0%, rgba(229,234,245,1) 100%);
  border-radius: 50px 50px 0;
  margin-top: 172px;
  padding: 48px 64px;
  box-sizing: border-box;
}

.hd-fgv_pricing h2 {
  width: 100%;
  font-size: 48px;
  color: var(--color-primary);
  font-weight: bold;
  margin: 0;
  text-align: center;
  margin-bottom: 36px;
}

.hd-fgv_pricing-top_table {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  height: 152px;
}

.hd-fgv_pricing-top_table span {
  width: 100%;
  font-size: 18px;
  line-height: 18px;
  color: var(--color-primary);
  font-weight: bold;
  text-align: left;
}

.hd-fgv_pricing-top_table > span {
  min-height: 38px;
}

.hd-fgv_pricing-top_table-bottom {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hd-fgv_pricing-top_table-bottom > div {
  display: flex;
  flex-direction: column;
}

.hd-fgv_pricing-top_table-bottom.space-between {
 justify-content: space-between;
}

.hd-fgv_pricing-top_table-bottom.content-end {
  justify-content: end;
}

.hd-fgv_pricing-top_table-bottom button {
  margin-top: 16px;
  background: var(--red) !important;
  cursor: pointer;
}

.hd-fgv_pricing-top_table-bottom button:hover {
  background: var(--color-primary) !important;
}

.hd-fgv_pricing-top_table small {
  width: 100%;
  color: #121212;
  font-size: 10px;
  text-align: left;
  font-weight: 400;
}

.price-variation {
  color: var(--color-primary);
  font-weight: bold;
  font-size: 20px;
}

.hd-fgv__pricing__table tbody tr:nth-child(2n + 1) {
  background-color: transparent;
}

.fgv-pricing_important {
  width: 100%;
  margin-top: 32px;
  background: #fff;
  box-shadow: 0 8px 24px rgba(0,0,0,0.1);
  border-radius: 20px 20px 0;
  padding: 44px 88px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fgv-pricing_important-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--red);
  font-weight: bold;
  font-size: 18px;
}

.fgv-pricing_important p {
  font-size: 16px;
  color: var(--color-primary);
  text-align: center;
}

.flex-col {
  flex-direction: column;
}


.fgv-container_hd-fgb {
  margin-top: 20px;
  width: 100%;
  max-width: 1280px;
}

.fgv-container_hd-fgb .hd-fgb__body {
  position: relative;
  height: 100vh;
  max-height: 720px;
  overflow: hidden;
  padding-bottom: 2em;
}

.fgv-container_hd-fgb .hd-fgb__body iframe {
  border: 0;
}

.fgv-ibre {
  margin-top: 148px;
}

.fgv-ibre > .fgv-container {
  flex-direction: column;
  gap: 144px;
}

.fgv-ibre_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.fgv-ibre_header h1 {
  color: var(--color-primary);
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}

.fgv-ibre_header p {
  width: 100%;
  color: var(--color-primary);
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.fgv-ibre_header ul {
  width: 100%;
  display: flex; 
  gap: 16px;
  padding: 0;
}

.fgv-ibre_header ul li {
  width: 100%;
  min-height: 206px;
  display: flex; 
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  background: #fff;
  border-radius: 24px;
  box-shadow: 0 8px 24px rgba(0,0,0,0.05);
  list-style: none;

  padding: 24px 40px;
}

.fgv-ibre_header ul li span {
  width: 100%;
  color: var(--color-primary);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.fgv-ibre_header ul li button {
  background: transparent;
  color: var(--red);
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 12px;
}

ul.fgv-benefits {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 92px;
  position: relative;
}
ul.fgv-benefits li {
  width: 100%;
  list-style: none;
}

.fgv-benefits_container {
  width: 100%;
  display: flex;
  gap: 68px;
}

.fgv-benefits_container img{
  border-radius: 40px 0 40px 0;
  box-shadow: -2px 8px 32px rgba(0,0,0,0.05);

}

ul.fgv-benefits li:nth-child(even) .fgv-benefits_container {
  flex-direction: row-reverse;
}

.fgv-benefits_detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
    justify-content: center;
  gap: 20px;
}

.fgv-benefits_detail h2 {
  font-size: 38px;
  color: var(--color-primary);
  font-weight: bold;
  line-height: 46px;
  margin: 0;
}

.fgv-benefits_detail span {
  font-size: 18px;
  color: #121212;
  margin: 0;
}

.fgv-benefits_detail a {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 84px;
  background: var(--red);
  color: #fff;
  border-radius: 50px;
  text-decoration: none;
}

.fgv-benefits_detail a:hover {
  background: var(--color-primary);
}

.hd-fgv__title {
  width: 100%;
  color: var(--color-primary);
  font-size: 38px;
  text-align: left;
}

.media-carousel {
  margin-top: 156px;
}

.hd-fgv__on-media__row {
  width: 100%;
}

.hd-fgv__on-media__item {
  box-shadow: none;
}

.horus__slider__control {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}

.horus__slider__control:hover {
  background-color: var(--color-primary);
}

.fgv-faq {
  margin-top: 128px;
}

.fgv-faq .hd-fgv__title {
  text-align: center;
  margin-bottom: 16px;
}

.hd-fgv__faq-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.hd-fgv__faq-item {
  background: #fff;
  border: none;
  border-radius: 16px;
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.05);
  padding: 16px 20px;
}

.hd-fgv__faq-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-primary);
  font-size: 20px;
}

.hd-fgv__faq-item__expand {
  font-size: 20px;
  width: fit-content;
  height: fit-content;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
}

.hd-fgv__faq-description a {
  color: var(--red);
  font-weight: bold;
}

.hd-fgv__on-media__col {
  width: 33%;
}

.hd-fgv__faq-item__expand::before, .hd-fgv__faq-item__expand::after {
  display: none;
}

.fgv-footer {
  color: var(--color-white) !important;
  background-color: var(--color-primary);
  padding: 60px 0;

  margin-top: 136px;
}

.footer-social {
  margin: 35px 0;
}

.footer-social a {
  color: var(--color-white);
  padding: 5px;
  text-decoration: none;
  display: inline-block;
  margin-right: 5px;
  background-size: cover;
  background-position: center;
  transition: all .3s;
  opacity: 1;
  position: relative;
}


.footer-social a > i {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.col-md-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

footer .menu li a {
  color: #bebebe;
  font-size: 12px;
  text-decoration: none;
  background-color: transparent;
}

footer .menu li a:hover {
  color: #bebebe;
  font-size: 12px;
  text-decoration: underline;
}

.col-md-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.text-muted {
  color: var(--color-white) !important;
  font-size: 14px;
  font-weight: 300;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}
.d-block {
  display: block !important;
}

.p-2 {
  padding: .5rem !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important
;
}

@media (min-width: 768px) {
  .col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
  .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (min-width: 576px) {
  .d-sm-none {
      display: none !important;
  }
}

span#planos {
  top: -90px;
  position: relative;
}

.hd-fgv__content .hd-fgv__section-1__text {
  font-size: 16px;
}

.hd-fgv__hero li span {
  max-width: 170px;
  display: flex;
  text-align: center;
  padding: 8px;
}

#produtos .hd-fgv__content {
  padding: 62px 0;
}

.hd-fgv__on-media .hd-fgv__content {
  padding: 32px 0 64px 0; 
}

#tool {
  top: -60px;
  position: relative;
}

.hd__modal__body {
  position: absolute;
  top: 300px;
}

span#sobre {
  position: relative;
  top: -100px;
}

.hd-fgv__hero .content ul li {
  display: inline-block;
}

.hd-fgv__hero .content ul li:nth-child(1) > button {
  margin: 4px 0 4px 0;
}

.hd-fgv__hero .content ul li button:hover {
  margin: 4px 0 4px 0;
  color: #fff;
  background: #b91f28;
  border-color: #b91f28;
}