.wrapper {
    max-width: 1200px;
    margin: auto;
    min-height: 600px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center
}

.wrapper img {
    max-width: 250px;
}