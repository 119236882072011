:root {
    --main-color: rgb(191, 30, 46);
}
.header {
    text-align: center;
    position: relative;
    height: auto;
    background-color: var(--main-color);
    color: #fff;
    padding-top: calc(50vh - 306px);
}
.header .container, .top {
    padding: 2px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 44px;
    color: #fff;
    height: 48px;

} 

.header .container .logo {
    height: 44px;
    width: 230px;
}

.welcomeText {
    color: #fff;
    font-size: 18px;
    padding: 32px 0;
    margin: 0;
}

.logoutSudoUser {
    background: unset;
    border: unset;
    color: #fff;
    font-size: 16px;
}

.top .hd-dash {
 overflow-x: unset;
}


.top .expirePlan {
    font-size: 13px;
    margin: 8px;
}